export * from './adders'
export * from './agreement-doc'
export * from './api-key'
export * from './applicant'
export * from './consent'
export * from './types'
export * from './equifax-credit-report'
export * from './loan-application'
export * from './document-types'
export * from './applicant-race'
export * from './citizenship'
export * from './error'
export * from './loan-calculations'
export * from './org'
export * from './presubmit-status'
export * from './street-address'
export * from './status-data'
export * from './masking'
export * from './mime-types'
export * from './individual'
export * from './installer'
export * from './tenant'
export * from './date-utils'
export * from './decision-pro'
export * from './form-types'
export * from './constants'
export * as Validate from './validators'
export * from './test-data'


export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NAME_REGEX = /^[a-zA-Z0-9.,#&()/\-\s\xC0-\uFFFF']+$/  // see test cases, allows diacritics and other
export const PHONE_REGEX = /^[0-9]{10}$/
export const DATE_REGEX = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/

export const pause = (secs: number) => new Promise(resolve => setTimeout(resolve, secs * 1000))

export const camelize = (str: string) => {
  return str.replace(/[^a-z ]/ig, '').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return "" // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const dollars = (num: number | bigint | string, maximumFractionDigits = 2): string => {
  let _num = num
  if (typeof _num === 'string') {
    _num = parseFloat(_num.replace(/[^0-9.]/g, ''))
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  }).format(_num)
}

export const dollarsToNum = (s: string): number => {
  const num = parseFloat(s?.trim().replace(/[^0-9.]/g, ''))
  return isNaN(num) ? 0 : num
}

export const digit2 = (x: number | string): string => {
  let _x = x
  if (typeof _x === 'string') {
    _x = parseFloat(_x)
  }

  return _x < 10 ? '0' + _x.toString() : _x.toString()
}

export const extractStreetFromAddress = (address: string): string => {
  // not exact, but good enough
  const [numAndStreet] = address.split(',')
  return numAndStreet.replace(/^\d+\s+/, '')
}
export interface MongooseModel {
  toObject: () => Record<string, unknown>
}

// lock existing properties, but allow modification of new ones
export const freezeprops = (obj: Record<string, unknown>): Record<string, unknown> => {
  Object.keys(obj).forEach(k => {
    Object.defineProperty(obj, k, {
      value: obj[k],
      writable: false,
      enumerable: true
    })
  })

  return obj
}