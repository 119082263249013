import {
  Dictionary,
  LoanDocument,
  StatusData,
  UploadDocType,
  FUND_RELEASE_SUPPORT_LINK,
  dateAndTime,
  dollars,
  AdderTypes,
  getCurrentFundRelease,
  getRequireDocsForStep,
  FUND_RELEASE_RESEND_WAIT_TIME_SECONDS,
  FundReleaseType 
} from "@oneethos/shared"
import { useEffect, useState } from "react"
import { useAppState } from "../../hooks"
import { uploadSlotFiles } from "../../services/fileUploadService"
import api from '../../api-client'
import { toast } from "react-toastify"
import { Spinner } from "@fluentui/react"
import { UploadSlot } from "./upload-slot"
import { DocActionBadge } from "../doc-action-badge"
import { GoQuestion } from "react-icons/go"
import { calculateFundReleaseAmount } from "@oneethos/shared"
import { Modal } from "../modal"
import { Badge } from ".."
import { errorToString } from '@oneethos/shared'

type FormState = {
  uploadType: UploadDocType
  stepDocs: Dictionary<LoanDocument & File>
}

export const InstallerUploadForm = ({ project, onProjectChange }) => {
  const [submitting, setSubmitting] = useState(false)
  const [sendingRequest, setSendingRequest] = useState(false)
  const { installersStatusConfig: isc } = useAppState()
  const step = isc.dict[project.installersStatus]
  const [formState, setFormState] = useState<FormState>({
    uploadType: step.uploadType,
    stepDocs: project.installerDocs[step.uploadType] || {}
  })

  const [showModal, setShowModal] = useState(false)
  const handleOpenModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  useEffect(() => {
    const _step = isc.dict[project.installersStatus]

    // this sequencing finds docs uploaded to a slot regardless of which step it was uploaded
    const projectDocs = {}
    Object.values(project.installerDocs).forEach(docMap => {
      Object.assign(projectDocs, docMap)
    })

    const stepDocs = {}
    _step.requiredDocuments.forEach(reqDoc => {
      stepDocs[reqDoc.slot] = projectDocs[reqDoc.slot]
    })

    setFormState({
      uploadType: _step.uploadType,
      stepDocs
    })
  }, [project, isc])

  const onSubmit = () => {
    setSubmitting(true)

    const docs = Object.entries(formState.stepDocs).filter(([, file]) => file && !file.status)
    uploadSlotFiles(docs).then(documents => {
      return api.put(`/loanapps/${project.id}/docs/submit`, {
        ...formState,
        documents
      }).then(proj => {
        const p = new StatusData(proj)
        p.uiSort()
        onProjectChange(p)
        toast.success('Documents uploaded successfully 🎉')
      })
    }).catch(ex => {
      const err = ex.error || ex.message || JSON.stringify(ex)
      toast.error(err, { autoClose: false })
    }).finally(() => setSubmitting(false))
  }

  const docs = Object.values(formState.stepDocs)
  const addedDocsCount = docs.filter(d => d && !d?.status).length
  const totalAddedDocs = docs.reduce((prev, cur) => prev + (cur && cur?.status !== 'comments' ? 1 : 0), 0)

  const fundReleaseTypeForStep = step.fundReleaseType
  const shouldShowFundRelease = !!fundReleaseTypeForStep

  const roofCostFundRelease = (project) => {
    const roofCost = parseFloat(project.roofCost)
    if (!isNaN(roofCost)) {
      return roofCost
    }
    
    const roofAdder = project.adders?.find(adder => 
      adder.description === AdderTypes.RoofPrice)

    return roofAdder?.amount || 0
  }

  const roofCost = roofCostFundRelease(project)

  const fundReleaseAmount = fundReleaseTypeForStep && calculateFundReleaseAmount(
    fundReleaseTypeForStep,
    project.loanAmount,
    roofCost
  )

  // This handles the scenario where loanAmount is updated, requiring us to fetch the 
  // updated fund release amount from Airtable using the finalFundReleaseAmount value.
  const adjustedFundReleaseAmount = fundReleaseTypeForStep === FundReleaseType.FinalFundingRelease 
  ? ( project.finalFundReleaseAmount || fundReleaseAmount )
  : fundReleaseAmount

  const currentFundRelease = getCurrentFundRelease(project, fundReleaseTypeForStep)
  
  const {
    docsNeedActionCount,
    requiredDocsCount,
    fundReleaseCompletedCount
  } = getRequireDocsForStep(project, step?.fundReleaseType )

  const completedDocsCount = totalAddedDocs + fundReleaseCompletedCount
  
  const renderFundReleaseStatus = () => {
    if (!shouldShowFundRelease) {
      return null
    }
    if (!currentFundRelease) {
      return (
        <div className="mb-3">
          <span className="text-small fw-bold">{`${fundReleaseTypeForStep} Request`}</span>
          <p className="text-small mb-1">
            Send {`${fundReleaseTypeForStep}`} request to customer for: <b>{`${dollars(adjustedFundReleaseAmount)}`} </b>
            <GoQuestion className="position-relative pointer" style={{ top: "-4px", color: "var(--oe-blue)" }} onClick={handleOpenModal} />
          </p>
          <button
            className="btn btn-secondary btn-sm"
            onClick={sendFundReleaseRequest}
          >
            {sendingRequest ? <Spinner /> : "Send Request"}
          </button>
        </div>
      )
    }

    if (currentFundRelease.agreementSigned?.agrees) {
      return (
        <div className="text-small" >
          <span className="fw-bold">{`${fundReleaseTypeForStep} Request`}</span>
          <div className="doc-status submitted">
            <p className="text-secondary">
              {`${fundReleaseTypeForStep} for ${dollars(adjustedFundReleaseAmount)} signed at ${dateAndTime(new Date(currentFundRelease.agreementSigned.timestamp))}`}
            </p>
            <Badge variant="success">Approved</Badge>
          </div>
        </div>
      )
    }

    if (currentFundRelease.requestedAt) {
      const sinceRequested = new Date().getTime() - currentFundRelease.requestedAt.getTime()
      const showResend = sinceRequested / 1000 > FUND_RELEASE_RESEND_WAIT_TIME_SECONDS

      return (
        <div className="text-small">
          <span className="fw-bold">{`${fundReleaseTypeForStep} Request`}</span>
          <div className="doc-status submitted ">
            <p className="text-secondary">
              {`${fundReleaseTypeForStep} for ${dollars(adjustedFundReleaseAmount)} requested at ${dateAndTime(new Date(currentFundRelease.requestedAt))}`}
            </p>
              {sendingRequest ? <Spinner /> : (
                showResend ? <p className="pointer">
                  <a className="ms-1 text-decoration-underline" onClick={handleResendRequest}>
                    Resend Request
                  </a>
                </p> : null
              )}
          </div>
        </div>
      )
    }
  }

  const sendFundReleaseRequest = () => {
    setSendingRequest(true)
    const payload = {
      fundReleaseType: fundReleaseTypeForStep,
      amount: adjustedFundReleaseAmount,
      requestedAt: new Date().toISOString(),
      agreementSigned: {
        browser: {},
        timestamp: null,
        agrees: false,
      }
    }
    api.post(`/loanapps/${project.id}/fund-release-request`, payload)
      .then((proj) => {
        const p = new StatusData(proj)
        onProjectChange(p)
        toast.success('Fund release request sent')
      }).catch(err => {
        toast.error(errorToString(err))
      }).finally(() => setSendingRequest(false))
  }

  const handleResendRequest = () => {
    setSendingRequest(true)
    api.post(`/loanapps/${project.id}/resend-fund-release-request`, {
      fundReleaseType: fundReleaseTypeForStep,
      fundReleaseAmount: adjustedFundReleaseAmount,
    })
      .then((proj) => {
        const p = new StatusData(proj)
        onProjectChange(p)
        toast.success('Fund release request resent')
      }).catch(err => {
        toast.error(errorToString(err))
      }).finally(() => setSendingRequest(false))
  }

  return (
    <>
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          title='Fund Release Request'
        >
          <p className="text-center">
            Clicking this button will send the customer an email that will allow them to approve
            these funds for release. This is used instead of an invoice. To learn more,
            visit <a href={FUND_RELEASE_SUPPORT_LINK} target="_blank">oneethos.com/support/fund-release</a>
          </p>
        </Modal>
      )}
      {step.uploadType ?
        <div className="project-docs">
          <div style={{ fontSize: '18px', marginBottom: '0.7em' }}>
            <DocActionBadge count={docsNeedActionCount} />
          </div>
          {renderFundReleaseStatus()}
          <div>{step.requiredDocuments.map(reqDoc => (
            <UploadSlot
              key={reqDoc.slot}
              doc={formState.stepDocs[reqDoc.slot]}
              slotConfig={reqDoc}
              onRemove={() => {
                const stepDocs = { ...formState.stepDocs }
                delete stepDocs[reqDoc.slot]
                setFormState({ ...formState, stepDocs })
              }}
              onChange={ev => setFormState({
                ...formState,
                stepDocs: {
                  ...formState.stepDocs,
                  [reqDoc.slot]: ev.target.files[0]
                }
              })}
            />
          ))}</div>
          <div className="alert alert-info">
            <div style={{ fontWeight: 'bold' }}>
              <div>
                {completedDocsCount} / {requiredDocsCount} documents added for this step.
              </div>
            </div>
            <div>
              We review documents in the order they are received. Once
              all documents are uploaded for a step, they are submitted to finance for approval.
            </div>
          </div>
          {addedDocsCount ? <div className="form-group">
            <button type="button"
              className="btn btn-primary"
              disabled={addedDocsCount === 0 || submitting}
              onClick={onSubmit}>{submitting ? <Spinner /> : "Save Documents"}</button>
          </div> : null}
        </div> :
        <div className="alert alert-info">
          No documents required for this step
        </div>}
    </>)
}

export default InstallerUploadForm
