export class MimeTypes {
  public static isValidFilename = (filename: string, options: {
    allowedExtensions?: string[];
    maxLength?: number;
    minLength?: number;
  } = {}): boolean => {
    // Default options
    const {
      allowedExtensions = [],
      maxLength = 255,
      minLength = 1
    } = options
  
    // Check if filename is empty or just whitespace
    if (!filename?.trim()) {
      return false
    }
  
    // Check length
    if (filename.length > maxLength) {
      return false
    }
  
    if (filename.length < minLength) {
      return false
    }
  
    // Check for invalid characters (Windows & Unix)
    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g
    if (invalidChars.test(filename)) {
      return false
    }
  
    // Check if filename starts or ends with period or spaces
    if (/^[. ]|[. ]$/.test(filename)) {
      return false
    }
  
    // Check extension if allowedExtensions is provided
    if (allowedExtensions.length > 0) {
      const ext = filename.toLowerCase().split('.').pop() || ''
      const normalizedAllowedExts = allowedExtensions.map(e => e.toLowerCase().replace(/^\./, ''))
  
      if (!normalizedAllowedExts.includes(ext)) {
        return false
      }
    }
  
    return true
  }

  public static COMMON_DOCUMENT_MIME_TYPES: Record<string, string[]> = {
    // Images
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/heic': ['.heic', '.HEIC'],
    'image/webp': ['.webp'],
    'image/svg+xml': ['.svg'],
    'image/bmp': ['.bmp'],
    'image/tiff': ['.tiff', '.tif'],
    'image/x-icon': ['.ico'],

    // Documents
    'application/pdf': ['.pdf', '.PDF'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'text/rtf': ['.rtf']
  }

  public static isAllowedCombo(t: string, ext: string): boolean {
    return this.COMMON_DOCUMENT_MIME_TYPES[t]?.includes(ext)
  }

  public static allowedUploadExtensions = (): string[] => {
    return Object.values(this.COMMON_DOCUMENT_MIME_TYPES).flat()
  }
}

export default MimeTypes