import { StatusData } from '@oneethos/shared'

type ProjectContactInfoProps = {
  project: StatusData
}

export const ProjectContactInfo = ({ project }: ProjectContactInfoProps) => {
  return <div>
    <div className="pr-info"><b>Customer Email:</b> {project.email}</div>
    <div className="pr-info"><b>Customer Phone:</b> {project.phone}</div>
    {project.includeCoBorrower === 'yes' ? <>
        <div className="pr-info"><b>Co-Borrower: </b> {[project.coFirstName, project.coLastName].filter(Boolean).join(' ')}</div>
        <div className="pr-info"><b>Co-Borrower Phone: </b> {project.coPhone}</div>
        <div className="pr-info"><b>Co-Borrower Email: </b> {project.coEmail}</div>
      </> : null
    }
  </div>
}

export default ProjectContactInfo
